<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-input
            v-model="searchParams.truckId"
            clearable
            placeholder="油罐号"
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="时间范围"
            clearable
            @change="getTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :loading="tableLoading"
      @currentChange="handleCurrentChange"
      stripe
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  QueryLevelGaugeData,
  ExportLevelGaugeData
} from '@/api/level_gauge_info';
import { columns } from './columns/level_gauge_data';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      searchParams: {
        siteId: this.$store.state.user.site_id
      },
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      tableLoading: false,
      date: ''
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.tableLoading = true;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryLevelGaugeData(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.tableLoading = false;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    // 导出
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportLevelGaugeData(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    getTime(e) {
      if (e) {
        this.searchParams.startTime = e[0];
        this.searchParams.endTime = e[1];
      } else {
        this.searchParams.startTime = '';
        this.searchParams.endTime = '';
      }
    }
  }
};
</script>
